<template>
  <div class="sidebar-separator">
    <p v-if="label" class="position-relative separator--title mt-2">
      <transition name="fade"
        ><span v-show="isMenuOpen" class="separator--label">{{
          label
        }}</span></transition
      >
      <span class="separator--line"></span>
    </p>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    label: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {};
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.sidebar-separator {
  .separator--title {
    transition: 0.2s;
    font-size: 11px;
    color: #858585;
    text-transform: uppercase;
    padding-bottom: 5px;
    .separator--label {
      padding-left: 14px;
      margin-right: 10px;
    }
  }
  span.separator--line {
    content: "";
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: #ddd;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}
</style>
